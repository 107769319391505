import React from "react"
import Layout from "../components/layout"

import heroIMG from '../images/hero.png'
import feature from '../images/feature1.png'
import paulig from '../images/paulig.png'
import redbull from '../images/redbull.png'
import mpk from '../images/mpk.jpg'
import ab from '../images/ab.png'
import karat from '../images/karat.png'
import hoch from '../images/hoch.png'

export default function Home() {
    return (
        <Layout>
            <section className="container grid-lg">
                <div className="columns">
                    <div className="column col-xs-12">
                        <div className="hero">
                            <div className="hero-body">
                                <h1>Автоматизация полевого персонала</h1>
                                <p>Простой и эффективный инструмент для мерчандайзинга</p>
                                <p>
                                    <a className="btn btn-primary btn-lg mr-2" href="" target="_blank">IOS</a>
                                    <a className="btn btn-primary btn-lg" href="" target="_blank">Android</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="column col-xs-12 text-center">
                        <figure className="figure">
                            <img className="rounded"
                                 src={heroIMG} alt="macOS Yosemite Wallpaper"
                                 style={{height: '20rem', 'max-width': '100%;'}} />
                        </figure>
                    </div>
                </div>
                <p className="text-gray text-center text-uppercase" style={{marginTop: '1.2rem'}}>Доверяют следующие компании и бренды</p>

                <div className="columns">
                    <div className="column col-xs-12 text-center">
                        <img className="img-fit-contain rounded"
                             src={paulig} alt="macOS Yosemite Wallpaper"
                             style={{height: '3rem', 'max-width': '100%;'}}/>
                    </div>
                    <div className="column col-xs-12 text-center">
                        <img className=" img-fit-contain rounded"
                             src={ab} alt="macOS Yosemite Wallpaper"
                             style={{height: '3rem', 'max-width': '100%;'}} />
                    </div>
                    <div className="column col-xs-12 text-center">
                        <img className="img-fit-contain rounded"
                             src={karat} alt="macOS Yosemite Wallpaper"
                             style={{height: '3rem', 'max-width': '100%;'}} />
                    </div>
                    <div className="column col-xs-12 text-center">
                        <img className="img-fit-contain rounded"
                             src={hoch} alt="macOS Yosemite Wallpaper"
                             style={{height: '3rem', 'max-width': '100%;'}} />
                    </div>
                    <div className="column col-xs-12 text-center">
                        <img className="img-fit-contain rounded"
                             src={mpk} alt="macOS Yosemite Wallpaper"
                             style={{height: '3rem', 'max-width': '100%;'}} />
                    </div>
                    <div className="column col-xs-12 text-center">
                        <img className="img-fit-contain rounded"
                             src={redbull} alt="macOS Yosemite Wallpaper"
                             style={{height: '3rem', 'max-width': '100%;'}} />
                    </div>
                </div>

                <div className="columns grid-hero">
                    <div className="column col-4 col-xs-12">
                        <div className="card text-center">
                            <div className="card-header">
                                <span className="card-title">GPS трекинг</span>
                            </div>
                            <div className="card-body">
                                Онлайн отслеживание посещения точек сотрудниками
                            </div>
                        </div>
                    </div>
                    <div className="column col-4 col-xs-12">
                        <div className="card text-center">
                            <div className="card-header">
                                <span className="card-title">Обучение</span>
                            </div>
                            <div className="card-body">Обучающие материалы прямо в приложении</div>
                        </div>
                    </div>
                    <div className="column col-4 col-xs-12">
                        <div className="card text-center">
                            <div className="card-header">
                                <span className="card-title">Мобильные приложения</span>
                            </div>
                            <div className="card-body">
                                Функциональные приложения для IOS и Android
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns bg-secondary text-center">
                    <div className="column">
                        <div className="hero hero-sm">
                            <div className="hero-body">
                                <h1>Ускорь свой бизнес</h1>
                                <p>
                                    <a className="btn btn-lg" href="/">Получить консультацию</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="columns grid-hero">
                    <div className="column col-xs-12 text-center">
                        <figure className="figure">
                            <img className="img-fit-contain rounded"
                                 src={feature} alt="macOS Yosemite Wallpaper"
                                 style={{height: '20rem', 'max-width': '100%;'}} />
                        </figure>
                    </div>
                    <div className="column">
                        <div className="hero">
                            <div className="hero-body">
                                <h1>Возможности</h1>
                                <ul>
                                    <li>Индивидуальная постановка задач на точку и сотрудника</li>
                                    <li>Настройка заполняемых отчетов SKU</li>
                                    <li>График посещения адресной программы</li>
                                    <li>Получение фото и координат напрямую с аппаратной оболочки, что исклбчает подделку данных</li>
                                    <li>Кабинет супервайзера</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}