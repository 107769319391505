import React from "react"
import { Link } from "gatsby"
import logoSVG from '../images/logo.svg'

export default function Layout({ children }) {
    return (
        <>
            <header className="navbar container grid-lg content">
                <section className="navbar-section">
                    <img src={logoSVG} alt='logo' height={'32'} />
                </section>
                <section className="navbar-section">
                    <a href='https://app.zrep.ru' className="btn mr-2">Войти</a>
                </section>
            </header>

            <main className={`content`}>
                {children}
            </main>

            <footer className="section section-footer">
                <div className="hero-footer container grid-lg" id="copyright">
                    <p>
                        <a href="" target="_blank">IOS</a> · <a href="" target="_blank">Android</a> · <a href="" target="_blank">64 бит apk</a> · <a href="" target="_blank">32 бит apk</a> · <span className={'text-gray'}>Версия 4.1.0</span></p>
                    <p>
                        Идея и разработка <span className="text-error">♥</span> <a href="" target="_blank">Zen Report</a>.
                    </p>
                </div>
            </footer>
        </>
    )
}